.breadcrumb_bg {
	@include background("../img/breadcrumb.png");
	border-radius: 0% 0% 50% 50% / 0% 0% 7% 7%;
}

.breadcrumb {
	position: relative;
	z-index: 1;

	&:after {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		content: "";
		opacity: 0.6;
		z-index: -1;
		border-radius: 0% 0% 50% 50% / 0% 0% 7% 7%;
	}

	.breadcrumb_iner {
		height: 400px;
		width: 100%;
		display: table;

		.breadcrumb_iner_item {
			display: table-cell;
			vertical-align: middle;

			h2 {
				color: $white_color;
				font-size: 56px;
				font-weight: 800;
				margin-bottom: 10px;
				text-transform: uppercase;

				@media #{$small_mobile} {
					font-size: 35px;
				}

				@media #{$large_mobile} {
					font-size: 35px;
				}

				@media #{$tab_device} {
					font-size: 40px;
				}

				@media #{$medium_device} {}
			}

			p {
				font-size: 18px;
				font-weight: 600;
				color: $white_color;
				text-transform: uppercase;
				a{
					color: $btn_bg;
				}
			}

			span {
				margin: 0px 5px;
				font-size: 12px;
			}

		}
	}

}

.breadcrumb {
	margin-bottom: 0px !important;
}