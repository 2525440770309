/**************** banner part css start ****************/
.banner_part{
    height: 880px;
    position: relative;
    overflow: hidden;
    background-image: url(../img/banner_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    border-radius: 0% 0% 50% 50% / 0% 0% 7% 7%;
    &:after{
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgb(23, 28, 58);
        z-index: -1;
        opacity: 0.8;
        border-radius: 0% 0% 50% 50% / 0% 0% 7% 7%;

    }
    @media #{$small_mobile}{
        height: 650px;
        background-color: #f0eed4;
    }
    @media #{$large_mobile}{
        height: 650px;
        background-color: #f0eed4;
    }
    @media #{$tab_device}{
        height: 700px;
        background-color: #f0eed4;
    }
    @media #{$medium_device}{
        background-size: cover;
        height: 650px;
    }
    
    .banner_text{
        display: table;
        width: 100%;
        height: 880px;
        .banner_text_iner{
            display: table-cell;
            vertical-align: middle;
            
        }
        @media #{$small_mobile}{
            text-align: center;
            padding-top: 0px;
            height: 650px;
        }
        @media #{$large_mobile}{
            text-align: center;
            padding-top: 0px;
            height: 650px;
        }
        @media #{$tab_device}{
            text-align: center;
            padding-top: 0px;
            height: 700px;
        }
        @media #{$medium_device}{
            height: 650px;
        }
        h5{
            font-size: 20px;
            letter-spacing: 4px;
            color: $white_color;
            text-transform: uppercase;
            span{
                color: $btn_bg;
            }
        }
        h1{
            font-size: 90px;
            line-height: 1;
            text-transform: uppercase;
            color: $white_color;
            font-weight: 900;
            margin-bottom: 0px;
            margin-top: 28px;
            @media #{$small_mobile}{
                font-size: 30px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$large_mobile}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$tab_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$medium_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.4;
            }
            span{
                color: $btn_bg;
            }
        }
        
        h3{
            font-size: 48px;
            font-weight: 300;
            color: $white_color;
            @media #{$small_mobile}{
                font-size: 28px;
            }
            @media #{$large_mobile}{
                font-size: 28px;
            }
            @media #{$tab_device}{
            
            }
            
        }
        .btn_1{
            margin-top: 45px;
        }
    }
    
    .video_popup {
        z-index: 99 !important;
        color: #333848;
        font-size: 14px;
        display: inline-block;
        font-weight: 500;
        text-transform: capitalize;
        span{
            width: 50px;
            height: 50px;
            background-color: $white_color;
            display: inline-block;
            border-radius: 50%;
            text-align: center;
            line-height: 50px;
            font-size: 20px;
            color: #fff;
            box-shadow: 0px 10px 30px 0px rgba(1, 84, 85, 0.2);
            color: #fe9c6f;
            margin-left: 37px;
            margin-right: 18px;
            padding-left: 5px;
            @media #{$small_mobile}{
                margin-left: 10px;
            }
            @media #{$large_mobile}{
            
            }
            @media #{$tab_device}{
            
            }
            @media #{$medium_device}{
            
            }
        }
        @media #{$small_mobile}{
            margin-left: 0px
        } 
    }
}
  
/**************** hero part css end ****************/
