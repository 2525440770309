/**************** gallery_part css start ****************/
.gallery_part{
    padding-top: 130px;
    @media #{$small_mobile}{
        padding-top: 70px;
    }
    @media #{$large_mobile}{
         padding-top: 70px;
    }
    @media #{$tab_device}{
         padding-top: 70px;
    }
    @media #{$medium_device}{
         padding-top: 70px;
    }
    .container-fluid{
        padding-left: 0px;
        padding-right: 0px;
    }

   .single_gallery_item{
       position: relative;
       width: 100%;
       height: 100%;
       z-index: 1;
       .single_gallery_item_iner{
           &:after{
               position: absolute;
               content: "";
               left: 0;
               top: 0;
               width: 100%;
               height: 100%;
               background-color: $black_color;
               z-index: -1;
               opacity: 0;
               @include transform_time(.5s);
               transform: scale(0.7);
           }
           .gallery_item_text{
                position: absolute;
                left: 20px;
                bottom: 80px;
                z-index: 5;
                visibility: hidden;
                opacity: 0;
                @include transform_time(.5s);
                h3{
                    color: $white_color;
                    font-size: 20px;
                    border-bottom: 2px solid $btn_bg;
                    padding-bottom: 10px;
                    margin-bottom: 5px;
                }
                p{
                    color: $white_color;
                }
            }
        }
        &:hover{
            .gallery_item_text{
                opacity: 1;
                visibility: visible;
                bottom: 50px;
            }
            .single_gallery_item_iner:after{
                opacity: 0.5;
                transform: scale(1);
            }
        }
   }

    /* clearfix */
    .grid:after {
        content: '';
        display: block;
        clear: both;
    }
    
    /* ---- grid-item ---- */
    
    .grid-sizer,
    .grid-item {
        width: 20%;
        @media #{$small_mobile}{
            width: 100%;
        }
        @media #{$large_mobile}{
            width: 50%;
        }
        @media #{$tab_device}{
            width: 50%;
        }
        @media #{$medium_device}{
        
        }
    }
    
    .grid-item {
        height: 430px;
        float: left;
        border: 5px solid $white_color;
    }
    
    .grid-item--width2 { 
        width:  40%; 
        @media #{$small_mobile}{
            width: 100%;
        }
        @media #{$large_mobile}{
            width: 50%;
        }
        @media #{$tab_device}{
            width: 50%;
        }
        @media #{$medium_device}{
        
        }
    }
    .grid-item--width3 { 
        width:  60%; 
        @media #{$small_mobile}{
            width: 100%;
        }
        @media #{$large_mobile}{
            width: 50%;
        }
        @media #{$tab_device}{
            width: 50%;
        }
        @media #{$medium_device}{
        
        }
    }
    .grid-item--height1 { 
        height: 415px; 
    }    
    .grid-item--height2 {
         height: 500px; 
    }
    .grid-item--height3 { 
        height: 585px; 
    }
    .bg_img{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
  }
    
