/**************** about css start ****************/
.about_part{
    position: relative;
    z-index: 1;
    .about_img{
        img{
            position: relative;
            z-index: 99;
        }
        position: relative;
        z-index: 2;
        &:after{
            position: absolute;
            left: -60px;
            top: -60px;
            width: 100%;
            height: 100%;
            background-image: url(../img/about_overlay.png);
            background-size: cover;
            background-position: center;
            content: "";
            z-index: -1;
        }
    }
    .about_text{ 
        position: relative;
        &:after{
            position: absolute;
            left: -200px;
            bottom: -111px;
            width: 100%;
            height: 100%;
            background-image: url(../img/about_overlay_2.png);
            background-size: cover;
            background-position: center;
            content: "";
            z-index: -1;
            @media #{$tab}{
                display: none;
            }
            @media #{$medium_device}{
                bottom: 0;
            }
            
        }
        @media #{$small_mobile}{
            margin-top: 20px;
        }
        @media #{$large_mobile}{
            margin-top: 30px;
        }
        @media #{$tab_device}{
            margin-top: 40px;
        }
        @media #{$medium_device}{
           
        }
        h4{
            margin-bottom: 31px;
            color: $btn_bg;
            font-size: 20px;    
            font-weight: 300; 
            @media #{$small_mobile}{
                margin-bottom: 20px;
                font-size: 17px;
            }
            @media #{$large_mobile}{
                margin-bottom: 20px;
                font-size: 17px;
            }
            @media #{$tab_device}{
                margin-bottom: 20px;
                font-size: 17px;
            }
            @media #{$medium_device}{
                margin-bottom: 20px;
                font-size: 17px;
            }       
        }
        h2{
            line-height: 1.25;
            margin-bottom: 25px;
            font-weight: 700;
            @media #{$small_mobile}{
                margin-bottom: 15px;
            }
            @media #{$large_mobile}{
                margin-bottom: 15px;
            }
            @media #{$tab_device}{
                font-size: 25px;  
                margin-bottom: 15px;
            }
            @media #{$medium_device}{
                font-size: 30px;  
                margin-bottom: 15px;
            }
        }
        p{
            margin-top: 13px;
            color: $font_7;
            font-weight: 300;
        }
    }
}
.our_service_part{
    background-image: url(../img/service_bg_2.png);
    background-repeat: no-repeat;
    background-position: left ;
    background-size: 29% 86%;
    @media #{$small_mobile}{
        padding-top: 0px;
        background-position: top left; 
        background-size: 100% 35%;
    }
    @media #{$large_mobile}{
        padding-top: 0px;
        background-position: top left;
        background-size: 95% 55%;
    }
    @media #{$tab_device}{
        background-position: left;
        background-size: 48% 70%;
    }
    @media #{$medium_device}{
    
    }
}